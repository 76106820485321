import { Dispatch, SetStateAction } from 'react'
import { Col, Container, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { FILTER_BY_STATUS, FilterTicketByDate } from './types'

interface DailySummaryProps {
    dailyTicketsCount: number
    maxDailyCount: number
    weekDate: Date
    setFilterByDateValue: Dispatch<SetStateAction<FilterTicketByDate>>
    setFilterByStatusValue: Dispatch<SetStateAction<FILTER_BY_STATUS>>
}

const DailySummary = ({
    dailyTicketsCount,
    maxDailyCount,
    weekDate,
    setFilterByDateValue,
    setFilterByStatusValue,
}: DailySummaryProps) => {
    const weekDay = weekDate.toLocaleDateString('DE', {
        weekday: 'short',
    })
    const tooltipId = `tooltip-${weekDay}`

    const dailyTicketsPercentage = (dailyTicketsCount / maxDailyCount) * 100

    return (
        <Col>
            <Container className='ds-day-bar-container'>
                <div className='text-center text-color-special mb-2 ds-day-bar-numer'>
                    {dailyTicketsCount}
                </div>
                {dailyTicketsCount > 0 && (
                    <OverlayTrigger
                        placement='top'
                        overlay={props => (
                            <Tooltip id={tooltipId} {...props}>
                                Tickets für {weekDate.toLocaleDateString('DE')}{' '}
                                anzeigen
                            </Tooltip>
                        )}
                    >
                        <div
                            className='ds-day-bar mx-auto mb-3 ds-mouse-pointer'
                            style={{
                                height: `calc(${dailyTicketsPercentage}% - 1rem)`,
                            }}
                            onClick={() => {
                                setFilterByDateValue({
                                    startDate: weekDate,
                                    endDate: weekDate,
                                })
                                setFilterByStatusValue(FILTER_BY_STATUS.Offen)
                            }}
                        />
                    </OverlayTrigger>
                )}
            </Container>
            <div className='text-center mb-3'>
                <h5>{weekDay}</h5>
            </div>
        </Col>
    )
}

export default DailySummary
