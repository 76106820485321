import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Form,
    FormControl,
    FormLabel,
    InputGroup,
    Modal,
    OverlayTrigger,
    Spinner,
    Stack,
    Tooltip,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { RequestPasswordChangeInput } from './types'
import { ResponseError, ResponseNotification } from 'common/types'
import ResponseAlert from 'common/alert/ResponseAlert'
import { useRequestPasswordChangeMutation } from 'app/api'
import { requestPasswordChangeValidationSchema } from './validationSchema'

interface RequestPasswordChangeFormProps {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    setLoginResponseNotification: Dispatch<
        SetStateAction<ResponseNotification | null>
    >
}

const RequestPasswordChangeForm = ({
    showModal,
    setShowModal,
    setLoginResponseNotification,
}: RequestPasswordChangeFormProps) => {
    const [
        responseNotification,
        setResponseNotification,
    ] = useState<ResponseNotification | null>(null)

    const {
        setFocus,
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isSubmitting, isSubmitted },
    } = useForm<RequestPasswordChangeInput>({
        defaultValues: { email: '' },
        resolver: yupResolver(requestPasswordChangeValidationSchema),
    })

    const [requestPasswordChange] = useRequestPasswordChangeMutation()

    const onSubmit = async (data: RequestPasswordChangeInput) => {
        try {
            await requestPasswordChange(data).unwrap()

            setLoginResponseNotification({
                status: 200,
                message:
                    'Für den E-Mail Bestätigungscode prüfen Sie bitte Ihr E-Mail-Postfach.',
            })

            setShowModal(false)
            reset()
        } catch (error) {
            const responseError = (error as ResponseError).data.error
            setResponseNotification(responseError)

            reset()
            setFocus('email')
        }
    }

    const handleClose = () => {
        setShowModal(false)
        setResponseNotification(null)
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton onClick={handleClose} />
            <Modal.Body>
                <h4 className='mb-3'>Passwort vergessen</h4>
                {responseNotification && (
                    <ResponseAlert
                        status={responseNotification.status}
                        message={responseNotification.message}
                    />
                )}
                Sie haben Ihr Passwort vergessen? Fordern Sie ein neues Passwort
                per E-Mail an.
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='d-flex mt-3 mb-3'>
                        <div className='w-100'>
                            <FormLabel htmlFor='change-email-input-field'>
                                Passwort an E-Mail Adresse
                            </FormLabel>
                            <InputGroup>
                                <InputGroup.Text
                                    className={
                                        isSubmitted
                                            ? errors.email?.message
                                                ? 'ds-form-is-invalid'
                                                : 'ds-form-is-valid'
                                            : ''
                                    }
                                >
                                    {errors.email ? (
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={props => (
                                                <Tooltip
                                                    id='tooltip-email-login'
                                                    {...props}
                                                >
                                                    {errors.email?.message}
                                                </Tooltip>
                                            )}
                                        >
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                />
                                            </i>
                                        </OverlayTrigger>
                                    ) : (
                                        <i>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                            />
                                        </i>
                                    )}
                                </InputGroup.Text>
                                <FormControl
                                    id='change-email-input-field'
                                    type='text'
                                    isValid={getValues('email') !== ''}
                                    isInvalid={
                                        errors.email?.message ? true : false
                                    }
                                    placeholder='Neue Nutzer E-Mail Adresse'
                                    aria-label='Neue Nutzer E-Mail Adresse Feld'
                                    {...register('email')}
                                />
                            </InputGroup>
                        </div>
                        <div className='ms-auto align-self-end ps-3'>
                            <Button
                                type='submit'
                                aria-label='Nutzer-Änderung Abfrage senden'
                            >
                                <Stack
                                    direction='horizontal'
                                    gap={2}
                                    className='justify-content-center'
                                >
                                    {isSubmitting && (
                                        <Spinner animation='border' size='sm' />
                                    )}
                                    <span>Senden</span>
                                </Stack>
                            </Button>
                        </div>
                    </div>
                </Form>
                <div className='ds-bg-section mt-4'>
                    <div className='fw-bold mb-3'>
                        Sie haben Fragen zum Task Manager?
                    </div>
                    <a
                        href='https://www.ds-destinationsolutions.com/kontakt'
                        className='btn btn-secondary px-4'
                        aria-label='DS Destination Solutions Kontakt-Link'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Hier erreichen Sie unsere Support Seite
                    </a>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    className='px-4 w-100'
                    onClick={handleClose}
                    aria-label='Passwort-Änderung Form schließen'
                >
                    Schließen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RequestPasswordChangeForm
