import { Dispatch, SetStateAction } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

import { FILTER_BY_STATUS } from 'features/dashboard/types'

type FilterByStatusBarProps = {
    filterValue: FILTER_BY_STATUS
    setFilterValue: Dispatch<SetStateAction<FILTER_BY_STATUS>>
}

const FilterByStatusBar = ({
    filterValue,
    setFilterValue,
}: FilterByStatusBarProps) => (
    <ButtonGroup className='ds-btn-group ds-btn-group-center'>
        {Object.values(FILTER_BY_STATUS)
            .filter(status => isNaN(Number(status)))
            .map((status, index) => (
                <Button
                    key={`status-filter-${index}}`}
                    type='button'
                    variant={filterValue === index ? 'primary' : 'text-dark'}
                    active={filterValue === index}
                    onClick={() => {
                        setFilterValue(index)
                    }}
                >
                    {status}
                </Button>
            ))}
    </ButtonGroup>
)

export default FilterByStatusBar
