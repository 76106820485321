import { Alert, Col, Row } from 'react-bootstrap'

import { ResponseNotification } from 'common/types'
import { mapVariant } from './utils'

type ResponseAlertProps = ResponseNotification

const ResponseAlert = ({ status, message }: ResponseAlertProps) => (
    <Row className='justify-content-center'>
        <Col>
            <Alert className='text-center' variant={mapVariant(status)}>
                {message}
            </Alert>
        </Col>
    </Row>
)

export default ResponseAlert
