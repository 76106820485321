import { ButtonGroup, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faDownload,
    faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons'

import { MappedTicket } from 'features/dashboard/types'
import { useExportTickets } from 'features/dashboard/hooks'
import { useSelector } from 'react-redux'
import { selectCurrentProvider } from '../auth/authSlice'

interface ExportMenuProps {
    tickets: MappedTicket[]
}

const ExportMenu = ({ tickets }: ExportMenuProps) => {
    const { exportPdfTickets } = useExportTickets()
    const provider = useSelector(selectCurrentProvider)

    return (
        <ButtonGroup className='ds-btn-group ds-dropdown-toggle' as={Dropdown}>
            <Dropdown.Toggle variant='text-dark'>
                <i>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={() => {
                        exportPdfTickets(provider, tickets)
                    }}
                >
                    <i className='text-color-n3 me-3'>
                        <FontAwesomeIcon icon={faDownload} size='lg' />
                    </i>
                    Gruppierte Tickets exportieren
                </Dropdown.Item>
            </Dropdown.Menu>
        </ButtonGroup>
    )
}

export default ExportMenu
