// import { useState } from 'react'

import CookiesConsentBanner from './CookiesConsentBanner'

const CookiesConsent = () => {
    // const [showCookiesConfig, setShowCookiesConfig] = useState<boolean>(false)

    return (
        <>
            <CookiesConsentBanner /* setShowCookiesConfig={setShowCookiesConfig} */
            />
            {/* {showCookiesConfig && <></>} */}
        </>
    )
}

export default CookiesConsent
