import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Image, Row } from 'react-bootstrap'

import Account from 'features/account/Account'
import { selectCurrentUser } from 'features/auth/authSlice'

import logoImage from 'assets/images/ds-logo-colour-rev-rgb.svg'
import settingsImage from 'assets/images/icon-settings.svg'

const NavBar = () => {
    const [showAccountForm, setShowAccountForm] = useState<boolean>(false)

    const user = useSelector(selectCurrentUser)

    return (
        <nav>
            <Container className='ds-bg-secondary'>
                <Row>
                    <Col>
                        <div className='navbar'>
                            <Image
                                src={logoImage}
                                alt='DS Destination Solutions'
                            />
                            {user && (
                                <>
                                    <Image
                                        className='ds-modal-accounticon-icon'
                                        src={settingsImage}
                                        alt='Konto Einstelungen'
                                        onClick={() => {
                                            setShowAccountForm(true)
                                        }}
                                    />
                                    <Account
                                        showModal={showAccountForm}
                                        setShowModal={setShowAccountForm}
                                    />
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </nav>
    )
}

export default NavBar
