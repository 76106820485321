import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Error from './Error'
import NoMatch from './NoMatch'
import ProtectedPage from './ProtectedPage'
import LoginPage from 'features/auth/LoginPage'
import DashboardPage from 'features/dashboard/DashboardPage'
import NavBar from 'features/nav/NavBar'
import Footer from 'features/footer/Footer'
import CookiesConsent from 'features/cookies-consent/CookiesConsentDialog'

const App = () => {
    return (
        <BrowserRouter>
            <CookiesConsent />
            <NavBar />
            <Routes>
                <Route path='*' element={<NoMatch />} />
                <Route
                    path='/'
                    element={
                        <ProtectedPage>
                            <DashboardPage />
                        </ProtectedPage>
                    }
                    errorElement={<Error />}
                />
                <Route
                    path='/login'
                    element={<LoginPage />}
                    errorElement={<Error />}
                />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default App
