import { Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Col,
    Row,
    Spinner,
    Image,
    OverlayTrigger,
    Tooltip,
    Form,
    InputGroup,
    FormControl,
    Button,
    Stack,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'

import { LoginInput } from './types'
import {
    AuthChallenge,
    ResponseError,
    ResponseNotification,
} from 'common/types'
import {
    useGetProviderMutation,
    useGetUserMutation,
    useLoginMutation,
} from 'app/api'
import { cleanAuthState, setProvider, setToken, setUser } from './authSlice'
import { loginValidationSchema } from './validationSchema'

import loginImage from 'assets/images/header-login.png'

interface LoginFormProps {
    setResponseNotification: Dispatch<
        SetStateAction<ResponseNotification | null>
    >
    email: string | null
    setEmail: Dispatch<SetStateAction<string | null>>
    setAuthChallenge: Dispatch<SetStateAction<AuthChallenge | null>>
    setShowRequestPasswordChangeForm: Dispatch<SetStateAction<boolean>>
}

const LoginForm = ({
    setResponseNotification,
    email,
    setEmail,
    setAuthChallenge,
    setShowRequestPasswordChangeForm,
}: LoginFormProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        setFocus,
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isSubmitting, isSubmitted },
    } = useForm<LoginInput>({
        defaultValues: { email: email || '', password: '' },
        resolver: yupResolver(loginValidationSchema),
    })

    const [login] = useLoginMutation()
    const [getUser] = useGetUserMutation()
    const [getProvider] = useGetProviderMutation()

    const onSubmit = async (data: LoginInput) => {
        setResponseNotification(null)

        setEmail(data.email)

        try {
            const loginData = await login(data).unwrap()

            if (loginData.challenge) {
                setAuthChallenge(loginData.challenge)
            }

            if (loginData.token) {
                dispatch(setToken(loginData.token))

                setResponseNotification(null)

                const getUserData = await getUser({
                    accessToken: loginData.token.access,
                }).unwrap()
                dispatch(setUser(getUserData))

                const getProviderData = await getProvider(
                    getUserData.providerId
                ).unwrap()
                dispatch(setProvider(getProviderData))

                navigate('/')
            }
        } catch (error) {
            const responseError = (error as ResponseError).data.error
            setResponseNotification(responseError)

            dispatch(cleanAuthState())

            reset()
            setFocus('email')
        }
    }

    return (
        <Row className='justify-content-center'>
            <Col className='col-12 col-sm-10 col-md-8'>
                <div className='d-flex align-items-end'>
                    <div className='ms-auto flex-shrink-1'>
                        <Image
                            src={loginImage}
                            alt='Login'
                            className='img-fluid ds-login-image'
                        />
                    </div>
                    <div className='ds-padding-left-1 w-100'>
                        <Row className='align-items-center mb-3'>
                            <Col className='col-12 col-sm-12 col-md-6'>
                                <h4 className='mb-0'>Dienstleister Login</h4>
                            </Col>
                            <div className='d-block d-sm-block d-md-none ds-spacer'></div>
                            <Col className='col-12 col-sm-12 col-md-6 text-end'>
                                <Link
                                    to='#'
                                    onClick={() => {
                                        setShowRequestPasswordChangeForm(true)
                                    }}
                                    className='ds-login-help-link'
                                    aria-label='Passwort-Änderung Form'
                                >
                                    Hilfe beim Login
                                </Link>
                            </Col>
                        </Row>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text
                                    className={
                                        isSubmitted
                                            ? errors.email?.message
                                                ? 'ds-form-is-invalid'
                                                : 'ds-form-is-valid'
                                            : ''
                                    }
                                >
                                    {errors.email ? (
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={props => (
                                                <Tooltip
                                                    id='tooltip-email-login'
                                                    {...props}
                                                >
                                                    {errors.email?.message}
                                                </Tooltip>
                                            )}
                                        >
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                />
                                            </i>
                                        </OverlayTrigger>
                                    ) : (
                                        <i>
                                            <FontAwesomeIcon icon={faUser} />
                                        </i>
                                    )}
                                </InputGroup.Text>
                                <FormControl
                                    type='text'
                                    isValid={getValues('email') !== ''}
                                    isInvalid={
                                        errors.email && errors.email.message
                                            ? true
                                            : false
                                    }
                                    placeholder='Nutzer E-Mail Adresse'
                                    aria-label='Nutzer E-Mail Adresse Feld'
                                    {...register('email')}
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text
                                    className={
                                        isSubmitted
                                            ? errors.password?.message
                                                ? 'ds-form-is-invalid'
                                                : 'ds-form-is-valid'
                                            : ''
                                    }
                                >
                                    {errors.password ? (
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={props => (
                                                <Tooltip
                                                    id='tooltip-email-login'
                                                    {...props}
                                                >
                                                    {errors.password?.message}
                                                </Tooltip>
                                            )}
                                        >
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                />
                                            </i>
                                        </OverlayTrigger>
                                    ) : (
                                        <i>
                                            <FontAwesomeIcon icon={faLock} />
                                        </i>
                                    )}
                                </InputGroup.Text>
                                <FormControl
                                    type='password'
                                    placeholder='Passwort'
                                    isValid={getValues('password') !== ''}
                                    isInvalid={
                                        errors.password &&
                                        errors.password.message
                                            ? true
                                            : false
                                    }
                                    aria-label='Passwort Feld'
                                    {...register('password')}
                                />
                            </InputGroup>
                            <Button
                                type='submit'
                                className='w-100'
                                aria-label='Nutzer einloggen'
                            >
                                <Stack
                                    direction='horizontal'
                                    gap={2}
                                    className='justify-content-center'
                                >
                                    {isSubmitting && (
                                        <Spinner animation='border' size='sm' />
                                    )}
                                    <span>Login</span>
                                </Stack>
                            </Button>
                        </Form>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default LoginForm
