import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'app/types'
import type { User, Token, Provider, EXPORT_FORMAT } from 'common/types'

type AuthState = {
    user: User | null
    token: Token | null
    provider: Provider | null
}

const initialState: AuthState = {
    user: null,
    provider: null,
    token: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        cleanAuthState: state => {
            state.user = null
            state.token = null
            state.provider = null
        },
        setUser: (state, { payload: user }: PayloadAction<User | null>) => {
            state.user = user
        },
        setProvider: (
            state,
            { payload: provider }: PayloadAction<Provider | null>
        ) => {
            state.provider = provider
        },
        setToken: (state, { payload: token }: PayloadAction<Token | null>) => {
            state.token = token
        },
        setExportFormat: (
            state,
            { payload: exportFormat }: PayloadAction<EXPORT_FORMAT>
        ) => {
            if (state.user) {
                state.user.exportFormat = exportFormat
            }
        },
    },
})

export const {
    cleanAuthState,
    setUser,
    setToken,
    setProvider,
    setExportFormat,
} = authSlice.actions

export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectCurrentUserEmail = (state: RootState) =>
    state.auth.user?.email
export const selectCurrentProvider = (state: RootState) => state.auth.provider
export const selectCurrentProviderId = (state: RootState) =>
    state.auth.provider?.id
export const selectCurrentAccessToken = (state: RootState) =>
    state.auth.token?.access
export const selectCurrentIdToken = (state: RootState) => state.auth.token?.id
export const selectCurrentRefreshToken = (state: RootState) =>
    state.auth.token?.refresh

export default authSlice.reducer
