import { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import { Row } from 'react-bootstrap'

import { FilterTicketByDate, FILTER_BY_STATUS, MappedTicket } from './types'
import DailySummary from './DailySummary'
import { selectCurrentProvider } from 'features/auth/authSlice'
import { useTickets } from './hooks'
import { getWeekDates } from './utils'

interface TicketsOverviewProps {
    tickets: MappedTicket[]
    openTicketsCount: number
    setFilterByDateValue: Dispatch<SetStateAction<FilterTicketByDate>>
    setFilterByStatusValue: Dispatch<SetStateAction<FILTER_BY_STATUS>>
}

const TicketsOverview = ({
    tickets,
    openTicketsCount,
    setFilterByDateValue,
    setFilterByStatusValue,
}: TicketsOverviewProps) => {
    const provider = useSelector(selectCurrentProvider)
    const { groupWeekTicketsByDay, getMaxTicketDailyCount } = useTickets()
    const groupedWeekTickets = groupWeekTicketsByDay(tickets)
    const maxTicketDailyCount = getMaxTicketDailyCount(groupedWeekTickets)
    const weekDates = getWeekDates()

    return (
        <header className='container ds-bg-secondary'>
            <Row>
                <h4 className='ds-company-name'>{provider?.name}</h4>
                {openTicketsCount === 1 && (
                    <span className='mb-3'>
                        {openTicketsCount} offenes Ticket
                    </span>
                )}
                {openTicketsCount !== 1 && (
                    <span className='mb-3'>
                        {openTicketsCount} offene Tickets
                    </span>
                )}
            </Row>
            <Row className='ds-diagram'>
                {groupedWeekTickets.map((ticketsGroup, index) => (
                    <DailySummary
                        key={`daily-summary-${index}`}
                        dailyTicketsCount={ticketsGroup.length}
                        maxDailyCount={maxTicketDailyCount}
                        weekDate={weekDates[index]}
                        setFilterByDateValue={setFilterByDateValue}
                        setFilterByStatusValue={setFilterByStatusValue}
                    />
                ))}
            </Row>
            <div className='ds-diagram-date text-center pt-4 pb-4'>
                {weekDates[0].toLocaleDateString('DE')} -{' '}
                {weekDates[6].toLocaleDateString('DE')}
            </div>
        </header>
    )
}

export default TicketsOverview
