import { Fragment } from 'react'

const NoMatch = () => {
    return (
        <Fragment>
            <header className='container ds-bg-secondary'>
                <div className='row'>
                    <h4 className='ds-company-name'>DS Dienstleister Portal</h4>
                    <span className='pb-6'>
                        Reinigung, Hausmeister, Wäscherei
                    </span>
                </div>
            </header>
            <section className='container ds-bg-secondary pt-5 pb-4'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-sm-10 col-md-8'>
                        <div className='alert alert-danger' role='alert'>
                            <b>Oops! </b>
                            Leider stimmt die angegebene URL nicht mit den
                            verfügbaren Routen überein.
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default NoMatch
