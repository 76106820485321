enum EXPORT_FORMAT {
    CSV = 'CSV',
    PDF = 'PDF',
    JSON = 'JSON',
    XML = 'XML',
}

enum TICKET_STATUS {
    OPEN,
    IN_PROGRESS,
    PROPOSED_DONE,
    DONE,
    OBSOLET,
    ARCHIVED,
    MARKED_TO_CANCEL,
}

enum TICKET_STATUS_MAP {
    'Offen',
    'Bearbeitung',
    'Fertig ',
    'Fertig durch Reinigungskraft',
}

enum TICKET_KIND {
    CLEANING = 'CLEANING',
    MAINTENANCE = 'MAINTENANCE',
}

enum TICKET_KIND_MAP {
    CLEANING = 'Reinigung',
    MAINTENANCE = 'Wartung',
}

interface User {
    username: string
    email: string
    providerId: string
    bsCompanyName: string
    emailVerified: boolean
    isAdmin: string
    exportFormat: EXPORT_FORMAT
}

interface Token {
    access: string
    id: string
    refresh: string
}

interface AuthChallenge {
    name: string
    session: string
}

interface ResponseNotification {
    status: number
    message: string
}

interface ResponseError {
    data: {
        error: ResponseNotification
    }
}

interface Address {
    city: string
    street: string
    zipcode: string
    additionalInfo?: string
}

interface AdditionalService {
    as_count: number
    as_name: string
}

interface Contact {
    name: string
    email: string
    phone: string
}

interface Provider {
    id: string
    name: string
    address: Address
    contact: Contact
    bsCompanyId: string
}

interface AdditionalInfo {
    cleaning?: string
    maintenance?: string
}

interface Child {
    age: number
    count: number
}

interface Room {
    id: string
    providerId: string
    address: Address
    additionalInfo?: AdditionalInfo
    bsCompanyId: string
    bsObjectId: number
    bsObjectName: string
    bsRoomId: number
    bsRoomName: string
    createdAt: number
    modifiedAt?: number
}

interface Ticket {
    id: string
    shortId: string
    createdAt: number
    kind: TICKET_KIND
    providerId: string
    roomId: string
    bsRoomId?: number
    roomName?: string
    objectId?: number
    objectName?: string
    objectAddress?: Address
    status: number
    content?: string
    earliestExecutionTimestamp: number
    plannedExecutionTimestamp: number
    latestExecutionTimestamp: number
    reservedFromTimestamp: number
    bsReservationId: string
    adults: number
    additionalServices?: AdditionalService[]
    childs?: Child[]
    notes?: string
    arrival: number
    departure: number
    nextAdults?: number
    nextChilds?: Child[]
    nextAdditionalServices?: AdditionalService[]
    nextNotes?: string
    nextArrival?: number
    nextDeparture?: number
    modifiedAt?: number
    inProgress: string
}

export {
    EXPORT_FORMAT,
    TICKET_STATUS,
    TICKET_STATUS_MAP,
    TICKET_KIND_MAP,
    TICKET_KIND,
}

export type {
    User,
    Token,
    AuthChallenge,
    ResponseNotification,
    ResponseError,
    Room,
    Provider,
    Ticket,
    Address,
    AdditionalService,
    AdditionalInfo,
    Child,
}
